@font-face {
  font-family: 'icons';
  font-display: swap;
  src: local('icons'), url(/_nuxt/fonts/icons.c62db0c.woff) format('woff');
}

.ico:before {
  font-family: 'icons';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}
